﻿.treeview {
    .treeview-item {
        margin: 0;
        padding: 0;
        position: relative;

        &:before {
            content: '';
            width: 2px;
            background: #eeeeee;
            height: calc(100% - 21px);
            position: absolute;
            left: 16px;
            bottom: 0;
            border-radius: 10px;

            @include size(tablet) {
                height: calc(100% - 31px);
            }
        }

        &:not(:last-child) {
            margin-bottom: 5px;

            @include size(tablet) {
                margin-bottom: 15px;
            }
        }

        &.values {
            &.note {
                font-style: italic;
            }

            h4 {
                opacity: 0.6;
            }
        }

        .treeview-item-head {
            width: 100%;
            /*height: 21px;*/
            display: flex;
            gap: 10px;
            padding: 2px 10px;
            cursor: pointer;
            /** {
                margin: 0;
                padding: 0;
            }*/

            @include size(tablet) {
                margin-bottom: 15px;
            }

            &:hover {
                background: #f5f5f5;
                border-radius: 10px;
            }

            .treeview-item-btn-colapse {
                display: flex;
                align-items: center;
                width: 15px;
                cursor: pointer;
                height: 19px;

                i {
                    font-size: var(--caretSize1);
                    width: 100%;
                    display: flex;
                    justify-content: center;

                    &.fa-circle, &.fa-dot-circle {
                        font-size: 7px;
                    }
                }
            }

            .treeview-item-title {
                display: flex;
                align-items: flex-start;
                width: 100%;
                gap: 10px;

                & > a {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    gap: 10px;
                }
                
                input[type="checkbox"] {
                    height: 19px;
                    margin: 0
                }

                h4 {
                    margin: 0;
                    padding: 0;
                    margin-right: auto;
                }
                /*.treeview-item-value {
                    margin-left: auto;
                }*/

                .treeview-item-legend {
                    padding: 1px 7px;
                    border-radius: 5px;
                    color: white;
                    display: none;
                }

                .treeview-item-value {
                    text-align: right;
                }

                .treeview-item-options {
                    height: 19px;
                    cursor: pointer;
                }
            }
        }

        .treeview-item-body {
            padding-left: 25px;
        }

        &.closed {
            /*margin-bottom: 0px;*/

            .treeview-item-body {
                display: none;
            }
        }
    }
}

.chart-legend-head {
    .treeview {
        overflow-y: auto;
        max-height: calc(100vh - 506px);
        height: 100%;

        @include size(desktop-large) {
            &.treeview {
                max-height: calc(100vh - 493px);
            }
        }
    }
}