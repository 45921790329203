﻿.feedback-module {
    z-index: 999999;
    width: 300px !important;

    @include size(mobile) {
        display: none;
    }
}

.feedback-btn {
    width: 30px;
    height: 30px;
    position: fixed;
    bottom: 50px;
    right: 50px;
    border: none;
    border-radius: var(--roundCorner2);
    background: var(--buttonColorGreen);
    -webkit-box-shadow: 0 5px 7px 0 rgba(21, 195, 154, 0.3);
    box-shadow: 0 5px 7px 0 rgba(21, 195, 154, 0.3);
    color: var(--textColorLight);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    z-index: 999999;

    &:hover {
        -webkit-box-shadow: rgba(21, 195, 154, 0.16) 0 10px 20px, rgba(21, 195, 154, 0.3) 0 6px 6px;
        box-shadow: rgba(21, 195, 154, 0.16) 0 10px 20px, rgba(21, 195, 154, 0.3) 0 6px 6px;
    }

    &:active {
        -webkit-box-shadow: rgba(21, 195, 154, 0.12) 0 1px 3px, rgba(21, 195, 154, 0.24) 0 1px 2px;
        box-shadow: rgba(21, 195, 154, 0.12) 0 1px 3px, rgba(21, 195, 154, 0.24) 0 1px 2px;
    }

    i {
        font-size: 13px;
    }
}

.feedback-form {

    h5 {
        text-align: center;
        margin-bottom: 10px;
    }
}

.feedback-spinner {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;

    .spinner-border {
        height: 25px;
        width: 25px;
    }
}

.form-group-screenshot-allow-btn {
    display: flex;
    flex-flow: row nowrap;

    input[type="checkbox"] {
        margin-left: var(--marginLeft3);
        width: fit-content;
    }
}

.form-group-screenshot {
    flex-flow: column wrap;
    gap: var(--marginBottom3);
    position: relative;
    align-items: flex-end;

    canvas {
        width: 100%;
    }
}

.feedback-form-hidden {
    display: none;
}

.feedback-formInputs-hide {
    display: none;
}

.feedback-confirmation-hide {
    display: none;
}

.feedback-screenshot-lightbox {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999;

    canvas#enlargedScreenshotContainer {
        max-width: 80vw;
        max-height: 80vh;
        background: #FFF;
    }
}

.feedback-screenshot-lightbox-close {
    position: absolute;
    top: 50px;
    right: 50px;
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    color: #FFF;
    font-size: 20px;
}

.deleteScreenshotBtn {
    border: none;
    margin: 0 !important;
    padding: 0;
    background: var(--buttonColorRed);
    width: 20px;
    height: 20px;
    border-radius: 20px;
    color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -10px;
    right: -10px;
    font-size: 10px;
}

.deleteScreenshotBtn-hide {
    display: none;
}

.feedback-screenshot-lightbox-hide {
    display: none;
}