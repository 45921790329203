
.WindmillContainer {
  width: 50%;
  position: relative;
  overflow: hidden;
}

.WindmillContainer::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}