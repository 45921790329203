﻿@import url("https://use.fontawesome.com/releases/v5.13.0/css/all.css");


/* --------------- Navigation --------------- */

.navigationBar {
    background-color: var(--styleColor2);
    color: var(--textColorLight);
    width: 200px;
    height: calc(100vh - 200px);
    margin: 0;
    position: fixed;
    top: 200px
}

.navigationBar-fixed {
    width: 200px !important;
}

.header-logo {
    background: none;
}

.nav-logo > a > img {
    width: 90px;
    position: absolute;
    top: 15px;
    left: 15px;
    z-index: 150;
}

.nav-menu {
    height: 100%;
}

    .nav-menu > ul {
        padding: 0;
        margin: 0;
        height: 100%;
    }

        .nav-menu > ul > li {
            list-style: none;
            height: 40px;
            padding: 0;
        }


            .nav-menu > ul > li > a {
                padding: 0;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                width: 100%;
                height: 100%;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
            }

.show-navbar .nav-icon-text {
    opacity: 1
}

.show-navbar .content-container {
    left: 200px;
    width: calc(100vw - 200px);
}

/*Moet aan de nav-item gegeven worden wanneer de pagina actief is*/
.active-nav-menu {
    background: var(--styleColor3);
}

.nav-icon {
    color: var(--textColorLight);
    /*margin-right: 40px;*/
    font-size: 18px;
    /*width: 35px;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 70px;
    min-width: 70px;
    height: 100%;
}

img.nav-icon {
    max-width: 70px;
    height: 28px;
}

.nav-icon.hide-on-show-navbar {
    overflow: hidden;
    -webkit-transition: width 0.3s ease-in-out, min-width 0.3s ease-in-out;
    -o-transition: width 0.3s ease-in-out, min-width 0.3s ease-in-out;
    transition: width 0.3s ease-in-out, min-width 0.3s ease-in-out;
    opacity: 1;
}

.nav-icon-text {
    color: var(--textColorLight);
    font-size: var(--inputFontSize);
    font-weight: var(--fontWeight2);
    opacity: 0;
    -webkit-transition: opacity 0.1s ease-in-out;
    -o-transition: opacity 0.1s ease-in-out;
    transition: opacity 0.1s ease-in-out;
}

.nav-mobile-menu-content .nav-icon-text {
    opacity: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0;
    width: 100%;
}

.nav-mobile-menu-content li[data-username="Energycommunity"] .nav-icon, .nav-mobile-menu-content li[data-username="ServicePoint"] .nav-icon {
    display: none !important;
}

li[data-username="Energycommunity"], li[data-username="ServicePoint"] {
    padding: 5px 0 !important;
}

.nav-icon-text-toggle {
    display: block;
    visibility: visible !important;
    opacity: 1 !important;
}

.pcoded-navbar.menupos-fixed {
    left: 500px;
}

.nav-disappear {
    visibility: visible;
    opacity: 1;
    -webkit-transition: visibility 0s, opacity 0.3s ease-in-out;
    -o-transition: visibility 0s, opacity 0.3s ease-in-out;
    transition: visibility 0s, opacity 0.3s ease-in-out;
    height: 100%;
}

.nav-disappear-fixed {
    visibility: hidden !important;
    opacity: 0 !important;
    height: 0 !important;
}

.nav-selections {
    visibility: hidden;
    opacity: 0;
    -webkit-transition: visibility 0s, opacity 0.3s ease-in-out;
    -o-transition: visibility 0s, opacity 0.3s ease-in-out;
    transition: visibility 0s, opacity 0.3s ease-in-out;
    height: 0;
}

.nav-selections-fixed {
    visibility: visible !important;
    opacity: 1 !important;
    height: 80px !important;
}

.nav-selection, #Organisations {
    height: 40px;
    margin: 0;
    width: 100%;
    max-width: 100%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 15px;
}

    .nav-selection > select {
        border-radius: 50px;
        border: none;
        position: relative;
        top: 50%;
        -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);
        height: 40px;
        padding: 6px 35px 5px 15px;
        font-size: 13px;
        background: var(--backgroundColor2);
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-image: url(data:image/svg+xml;charset=US-ASCII,%3Csvg%20aria-hidden%3D%22true%22%20focusable%3D%22false%22%20data-prefix%3D%22fas%22%20data-icon%3D%22caret-down%22%20class%3D%22svg-inline--fa%20fa-caret-down%20fa-w-10%22%20role%3D%22img%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20320%20512%22%3E%3Cpath%20fill%3D%22currentColor%22%20d%3D%22M31.3%20192h257.3c17.8%200%2026.7%2021.5%2014.1%2034.1L174.1%20354.8c-7.8%207.8-20.5%207.8-28.3%200L17.2%20226.1C4.6%20213.5%2013.5%20192%2031.3%20192z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E);
        background-position: right 1.1em top 54%, 0 0;
        background-size: .65em auto, 100%;
        background-repeat: no-repeat, repeat;
    }

        .nav-selection > select::after {
            position: absolute;
            font-family: 'FontAwesome';
            top: 0;
            left: 10px;
            content: "\f0d7";
        }

    #Organisations > div > span > span.selection > span, .select2-container--default .select2-selection--single {
        border-radius: 50px;
        border: none;
        height: 30px;
        padding: 4px 35px 4px 15px;
        font-size: 13px;
        background: var(--backgroundColor2);
    }

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
}

#Organisations > div > span, body > nav > div > ul > div > div.navbar-select-wrapper.nav-selection > span {
    /*position: relative;
    top: 50%;
    transform: translate(0, -50%);*/
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 22px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 1px;
    right: 10px;
}

    .select2-container--default .select2-selection--single .select2-selection__arrow b {
        border-color: #333 transparent transparent transparent;
    }

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: transparent transparent #333 transparent;
}

.select2-container--open .select2-dropdown--below {
    border-radius: 10px;
    padding: 10px;
    width: 250px;
}

.select2-search--dropdown {
    padding: 0;
    margin: 0 0 15px 0;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
    width: 100%;
    padding: var(--padding4);
    border-radius: var(--roundCorner2);
    border: none;
    font-size: var(--inputFontSize);
    background-color: var(--backgroundColor2);
}

.nav-version {
    font-size: 12px;
}

.navigationBar-mobile {
    display: none;
}

.nav-pin {
    position: absolute;
    width: 280px;
    height: 200px;
    top: -200px;
    /*visibility: hidden;
    opacity: 0;*/
}

.nav-pin-item {
    font-size: 20px;
    position: absolute;
    bottom: 50px;
    cursor: pointer;
}

    .nav-pin-item .nav-icon {
        margin: 0;
    }

.marked-item {
    color: var(--styleColor3);
}

.error-link {
    color: var(--buttonColorRed);

    &::after {
        content: url(../../../images/icons/exclamation-circle-solid.svg);
        color: var(--buttonColorRed);
        height: fit-content;
        width: 20px;
        margin: 0 0 0 10px;
        position: relative;
        top: 1px;
    }
    /*&.nav-link {
        height: 14px;
    }*/
}

.mobile-legend-btn.error-link::after {
    position: absolute;
    top: -4px;
    right: -5px;
}

.error-link i, .error-link span {
    color: var(--buttonColorRed);
}

/* ----- Hover ----- */

.show-navbar .hide-on-show-navbar {
    width: 0;
    min-width: 0;
    opacity: 0;
}

.show-navbar .nav-icon-text {
    visibility: visible;
    opacity: 1;
}

.show-navbar .nav-selections {
    visibility: visible;
    opacity: 1;
    height: 80px;
}

.show-navbar .nav-disappear {
    visibility: hidden;
    opacity: 0;
    -webkit-transition: visibility 0s, opacity 0.3s ease-in-out;
    -o-transition: visibility 0s, opacity 0.3s ease-in-out;
    transition: visibility 0s, opacity 0.3s ease-in-out;
    height: 0;
}

.show-navbar .nav-disappear {
    display: none;
}

.nav-item:hover .nav-icon, .nav-item:hover .nav-icon-text {
    color: var(--styleColor3);
}

/*.select2-container {
    width: 150px !important;
}*/

.active-nav-menu:hover .nav-icon, .active-nav-menu:hover .nav-icon-text {
    color: #333333;
}

.active-nav-menu:hover img.nav-icon {
    -webkit-filter: invert(80%);
    filter: invert(80%);
}

/* --------------- Top bar --------------- */
.nav-topBar {
    color: var(--textColorLight);
    width: 100%;
    /*height: 70px;*/
    padding: 15px 110px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

    .nav-topBar a {
        color: var(--textColorLight);
    }

        .nav-topBar a:hover i, .nav-topBar a:hover h6 {
            color: var(--styleColor3);
        }

.nav-topBar-ul {
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    margin: 0;
}

    .nav-topBar-ul > li {
        margin-left: 25px;
    }

        .nav-topBar-ul > li:first-child {
            margin-left: 0;
        }

        .nav-topBar-ul > li:last-child {
            /*margin-right: 50px;*/
        }

.nav-search {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 30px;
    color: #333333;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin-right: -14px;
    display: none;
}

    .nav-search > a {
        position: relative;
        left: -25px;
        top: 5px;
        font-size: var(--searchBtnFontSize);
    }

        .nav-search > a > i {
            color: var(--styleColor2);
        }

.searchBar {
    width: 250px;
    padding: 7px 35px 7px 15px;
    border-radius: 50px;
    border: none;
    font-size: 13px;
}

.nav-profile {
    height: 30px;
    z-index: 1100;
}

    .nav-profile > .profile-icon {
        position: relative;
        top: 50%;
        -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);
    }

.profile-btn {
    font-size: 18px;
    position: relative;
    top: 1px;
    margin-right: 0 !important;
}

.profile-btn-active {
    color: var(--styleColor3);
    font-size: 18px;
    display: block;
    float: right;
    margin-right: 0 !important;
}

.dropdown-toggle::after {
    /*left: 0;*/
    display: none;
}

.nav-topBar-dropdown {
    position: absolute;
    width: 250px;
    top: -14px;
    left: -207px;
    padding: 15px 20px 20px 20px;
    border: none;
    border-radius: 10px;
    background: #FFFFFF;
    color: #333333;
    -webkit-box-shadow: 0px 10px 60px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 10px 60px 0px rgba(0,0,0,0.2);
    display: none;
    /*z-index: 50;*/
    max-height: 80vh;
}

    .nav-topBar-dropdown > ul {
        list-style: none;
        padding: 0;
        max-height: calc(80vh - 35px - 15px - 8px - 15px - 59px - 30px);
        overflow: auto;
    }

        .nav-topBar-dropdown > ul > li {
            text-align: left;
            line-height: 20px;
        }

            .nav-topBar-dropdown > ul > li > a {
                text-align: right;
                color: #333333;
                font-weight: 700;
            }

.nav-topBar-dropdown-name {
    font-weight: 700;
}

.pro-head {
    /*margin-bottom: 8px;*/
    border-bottom: 1px solid rgba(102, 102, 102, 0.5);
    padding-bottom: 15px;
    height: 35px;
}

.nav-topBar-head {
    /*margin-bottom: 8px;
    border-bottom: 1px solid rgba(102, 102, 102, 0.5);
    padding-bottom: 15px;*/
}

.spacer-line {
    border-bottom: 1px solid rgba(102, 102, 102, 0.5);
    width: 100%;
    height: 0;
    margin: 11px 0 8px 0;
}

.nav-topBar-submenu {
    display: none;
    list-style: none;
    color: #333;
    padding: 0 0 10px 20px;
    border-top: 1px solid rgba(102, 102, 102, 0.5);
}

    .nav-topBar-submenu > li > a:hover {
        color: #000;
    }

    .nav-topBar-submenu > li > a {
        color: #333;
    }

.nav-topBar-visible {
    display: block;
}

.nav-search-removed {
    left: -207px;
}

.dropdown-search {
    width: 100%;
    position: relative;
    margin: 0;
    padding: 15px 0;
}

    .dropdown-search input {
        width: 100%;
        padding: var(--inputPadding);
        border-radius: var(--roundCorner2);
        border: none;
        font-size: var(--inputFontSize);
        background-color: var(--backgroundColor2);
    }

    .dropdown-search button {
        position: absolute;
        right: 11px;
        top: 19px;
        border: none;
        background: none;
        padding: 0;
        margin: 0;
        font-size: var(--searchBtnFontSize);
    }

.nav-topbar-highlight {
    font-weight: 700;
}

    .nav-topbar-highlight::after {
        font-family: "Font Awesome 5 Free";
        content: "\f0d7";
        padding-left: 10px;
    }

.nav-footer {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /*justify-content: space-between;*/
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 40px;
    padding: 15px 0;
    margin: 50px 0 0 0;
}

.nav-footer-mobile {
    width: 100% !important;
    display: none;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 40px;
    padding: 0;
    margin: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    height: unset;
    padding: 0 7vw 15px 7vw !important;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
}

.nav-footer > p, .nav-footer > ul, .nav-footer > li, .nav-footer > ul > li > a {
    color: $default-font-color-dark;
    font-size: 9px;
    margin: 0;
    padding: 0;
}

.nav-footer-mobile > p, .nav-footer-mobile > ul, .nav-footer-mobile > li, .nav-footer-mobile > ul > li > a {
    color: $default-font-color-light;
    font-size: 9px;
    margin: 0;
    padding: 0;
    width: 100%;
    text-align: center;
}

.nav-footer > p {
    margin-right: 15px;
}

.nav-footer > ul {
    text-decoration: none;
    list-style: none;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-column-gap: 15px;
    -moz-column-gap: 15px;
    column-gap: 15px;
    /*margin: 0 365px 0 auto;*/
}

.nav-footer-mobile > ul {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.nav-footer a:hover {
    color: var(--styleColor1);
}

.nav-footer-login {
    width: calc(100% - 500px);
    position: absolute;
    bottom: 0;
    padding: 0 60px 0 70px;
}

    .nav-footer-login.nav-footer-mobile > p, .nav-footer-login.nav-footer-mobile > ul, .nav-footer-login.nav-footer-mobile > li, .nav-footer-login.nav-footer-mobile > ul > li > a {
        color: $default-font-color-dark;
        display: none;
    }

.select2-container--default .select2-selection--single .select2-selection__arrow b {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.nav-connectionPoint-group {
    height: 100%;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin-right: 25px;
}

    .nav-connectionPoint-group > * {
        height: 100%;
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
    }

    .nav-connectionPoint-group:last-child {
        margin-right: 0;
    }

    .nav-connectionPoint-group h6 {
        margin: 0;
        font-size: 11px;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
    }

.nav-connectionPoint-icon {
    margin-right: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

    .nav-connectionPoint-icon:before {
        -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
    }

.nav-lang {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: center;
}

    .nav-lang .current-language, .drop .current-language {
        border: none;
    }

        .nav-lang .current-language:after, .drop .current-language:after {
            content: "\f107";
            font-family: "Font Awesome 5 Free";
            font-weight: 700;
            position: relative;
            left: 3px;
        }

        .nav-lang .current-language:hover, .nav-lang .current-language:focus, .drop .current-language:hover, .drop .current-language:focus {
            color: var(--styleColor3);
        }

.current-language {
    color: $default-font-color-dark;
}

.login-lang {
    position: absolute;
    top: calc(60px + 75px);
    right: calc(100px + 8px);
}

.dropdown-menu.show {
    border-radius: $default-border-radius;
    background: var(--backgroundColor1);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    min-width: 150px;
    border: none;
    -webkit-box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.2);
    /*box-shadow: var(--shadow3);*/
    margin: 0;
    padding: 0;
}

    .dropdown-menu.show a {
        padding: 5px 20px;
        width: 100%;
        color: $default-font-color-dark;
        cursor: pointer;
        font-weight: var(--fontWeight3);
        text-align: right;
        line-height: var(--lineHeight1);
        font-family: $default-font-family-text;
        font-size: $default-font-size;
    }

        .dropdown-menu.show a:first-child {
            margin-top: var(--marginTop4);
        }

        .dropdown-menu.show a:last-child {
            margin-bottom: var(--marginBottom4);
        }

.dropdown-menu > a.active, .dropdown-menu > a:active, .dropdown-menu > a:focus {
    background: var(--styleColor3);
}

.dropdown-menu > a:hover {
    background: var(--backgroundColor2);
}

.nav-mobile-menu {
    display: none;
    z-index: 1160;
}

.select2-dropdown {
    z-index: 2051;
}

.nav-mobile-menu-container > a {
    height: 30px;
}

.nav-mobile-hamburger {
    font-size: 20px;
    position: relative;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
}

/*.fa-times {
    font-size: 30px;
}*/

.onlyMobile {
    display: none;
}

.nav-mobile-menu > .nav-mobile-menu-content > ul > .nav-disappear {
    display: none;
}

.nav-mobile-menu > .nav-mobile-menu-content > ul > .nav-selections {
    visibility: unset;
    opacity: unset;
    -webkit-transition: unset;
    -o-transition: unset;
    transition: unset;
    height: unset;
}

.nav-mobile-menu > .nav-mobile-menu-content > ul > li.nav-item {
    padding: 15px 0;
}

    .nav-mobile-menu > .nav-mobile-menu-content > ul > li.nav-item > a.nav-link {
        /*font-size: 30px;*/
        padding: unset;
        position: unset;
        top: unset;
        -webkit-transform: unset;
        -ms-transform: unset;
        transform: unset;
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        width: 100%;
    }

        .nav-mobile-menu > .nav-mobile-menu-content > ul > li.nav-item > a.nav-link > i.nav-icon {
            width: 50px;
            display: -webkit-inline-box;
            display: -ms-inline-flexbox;
            display: inline-flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -ms-flex-item-align: center;
            align-self: center;
            margin-right: 10px;
        }

        .nav-mobile-menu > .nav-mobile-menu-content > ul > li.nav-item > a.nav-link > span.nav-icon-text {
            visibility: unset;
            opacity: unset;
            font-size: 17px;
            color: unset;
            font-weight: unset;
            position: unset;
            top: unset;
            left: unset;
            -webkit-transform: unset;
            -ms-transform: unset;
            transform: unset;
            -webkit-transition: unset;
            -o-transition: unset;
            transition: unset;
        }

.nav-mobile-menu > .nav-mobile-menu-content > ul > div.nav-selections {
    margin-bottom: 10px;
}

.nav-mobile-menu > .nav-mobile-menu-content > ul > li.nav-item:last-child {
    position: absolute;
    width: calc(100% - 20vw);
    bottom: 25px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

    .nav-mobile-menu > .nav-mobile-menu-content > ul > li.nav-item:last-child > a > i.nav-icon {
        display: none;
        font-size: 11px;
    }

    .nav-mobile-menu > .nav-mobile-menu-content > ul > li.nav-item:last-child > a > span.nav-version {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        font-size: 11px;
        visibility: visible;
        opacity: 1;
        white-space: nowrap;
        position: absolute;
        top: unset;
        bottom: 15px;
        left: 50%;
        -webkit-transform: translate(-50%);
        -ms-transform: translate(-50%);
        transform: translate(-50%);
    }

.menuOpenShow {
    display: block;
}

/* ----- Hover ----- */
.nav-topBar-dropdown > ul > li > a:hover {
    color: #000000;
}

/* --------------- Content section --------------- */
.content-container {
    border-top-left-radius: 150px;
    padding: 75px 100px 0 100px;
    background-color: #FFFFFF;
    background-image: url(../../../images/clouds.svg);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    height: calc(100vh - 60px);
    /*height: -webkit-fill-available;
    height: fill-available;*/
    width: calc(100vw - 70px);
    overflow-y: scroll;
    top: 60px;
    left: 70px;
    -webkit-box-shadow: 20px 40px 50px 0px rgba(0,0,0,1);
    box-shadow: 20px 40px 50px 0px rgba(0,0,0,1);
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    /*transform: translateZ(0);*/ /* Dit is een fix voor de wolken die de overflow niet respecteren in Safari  */
    /*    -ms-overflow-style: none;  IE and Edge 
    scrollbar-width: none;  Firefox */
}

/*    .content-container::-webkit-scrollbar {
        display: none;
    }*/

.navigationBar:hover .content-container {
    left: 200px;
    width: calc(100vw - 200px);
}

/*.pcoded-wrapper, .pcoded-content, .pcoded-inner-content, .main-body, .page-wrapper {
    height: 100%;
}*/

.content-container .page-wrapper {
    min-height: calc(100vh - 60px - 165px);
}





/* --------------- Content Section Error --------------- */
.content-container-error {
    padding: 7vw;
}

/* --------------- Servicepoint and energycommunity --------------- */

.select2-results__option {
    color: $default-font-color-dark;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: var(--styleColor3);
    color: $default-font-color-dark;
    font-weight: var(--fontWeight3);
}


/* --------------- Responsive --------------- */

@media only screen and (max-width: 1500px) {
    .nav-footer-login {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        line-height: 20px;
        padding: 0 15px 15px 60px;
    }
}


@media only screen and (max-width: 1224px) {
    body {
        /*border-top: 5px solid green;*/
    }

    .content-container {
        padding: 75px 80px 0 80px;
    }

    .nav-topBar {
        padding: 15px 90px;
    }

    .nav-footer {
        width: calc(100% - 370px);
    }

    .nav-footer-login {
        width: calc(100% - 500px);
    }
}

@media only screen and (max-width: 1150px) {
    .nav-footer {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        line-height: 20px;
        padding: 0 15px 15px 0;
    }

        .nav-footer ul {
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            margin: 0;
        }

    .nav-footer-login {
        padding: 0 15px 15px 60px;
    }

        .nav-footer-login.nav-footer-mobile > p, .nav-footer-login.nav-footer-mobile > ul, .nav-footer-login.nav-footer-mobile > li, .nav-footer-login.nav-footer-mobile > ul > li > a {
            display: flex;
            flex-flow: row wrap;
        }
}

@media only screen and (max-width: 950px) {
    .nav-connectionPoint {
        display: none;
    }
}

@media only screen and (max-width: 768px), only screen and (max-device-width: 896px) and (orientation: landscape) {
    body {
        border-top: none;
        background: #FFFFFF;
    }

    .content-container {
        border-radius: 0;
        left: 0;
        width: 100vw;
        /*height: calc(100vh - 70px);*/
        height: 100vh;
        -webkit-box-shadow: 20px 40px 50px 0px rgba(0,0,0,0);
        box-shadow: 20px 40px 50px 0px rgba(0,0,0,0);
        padding: 50px 7vw 95px 7vw;
        overflow-x: hidden;
    }

    .nav-logo > a > img {
        top: 15px;
        left: 5vw;
        width: 40px;
    }

    .nav-topBar {
        background: var(--styleColor2);
        padding: 15px 7vw;
    }

    .nav-topBar-ul > li:last-child {
        margin-right: 5px;
    }

    .navigationBar {
        display: none;
    }

    .navigationBar-mobile {
        display: block;
        position: absolute;
        bottom: 25px;
        width: 90vw;
        left: 50%;
        -webkit-transform: translate(-50%);
        -ms-transform: translate(-50%);
        transform: translate(-50%);
    }

    .bottomBar {
        height: 70px;
        width: 100%;
        border-radius: 50px;
        background: var(--styleColor2);
    }

        .bottomBar > ul {
            list-style: none;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: space-evenly;
            -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            width: 100%;
            padding: 0 30px;
            margin: 0;
            position: absolute;
            top: 50%;
            -webkit-transform: translate(0, -50%);
            -ms-transform: translate(0, -50%);
            transform: translate(0, -50%);
        }

    .nav-icon {
        margin: 0;
    }

    .nav-link {
        padding: 0;
    }

    .nav-footer {
        display: none;
    }

    .nav-footer-mobile {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        position: relative;
        align-self: flex-end;
    }

    .ham {
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;
        -webkit-transition: -webkit-transform 400ms;
        transition: -webkit-transform 400ms;
        -o-transition: transform 400ms;
        transition: transform 400ms;
        transition: transform 400ms, -webkit-transform 400ms;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .hamRotate.active {
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    .line {
        fill: none;
        -webkit-transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
        -o-transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
        transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
        stroke: var(--backgroundColor2);
        stroke-width: 8;
        stroke-linecap: round;
    }

    .ham8 .top {
        stroke-dasharray: 40 160;
    }

    .ham8 .middle {
        stroke-dasharray: 40 142;
        -webkit-transform-origin: 50%;
        -ms-transform-origin: 50%;
        transform-origin: 50%;
        -webkit-transition: -webkit-transform 400ms;
        transition: -webkit-transform 400ms;
        -o-transition: transform 400ms;
        transition: transform 400ms;
        transition: transform 400ms, -webkit-transform 400ms;
    }

    .ham8 .bottom {
        stroke-dasharray: 40 85;
        -webkit-transform-origin: 50%;
        -ms-transform-origin: 50%;
        transform-origin: 50%;
        -webkit-transition: stroke-dashoffset 400ms, -webkit-transform 400ms;
        transition: stroke-dashoffset 400ms, -webkit-transform 400ms;
        -o-transition: transform 400ms, stroke-dashoffset 400ms;
        transition: transform 400ms, stroke-dashoffset 400ms;
        transition: transform 400ms, stroke-dashoffset 400ms, -webkit-transform 400ms;
    }

    .ham8.active .top {
        stroke-dashoffset: -64px;
    }

    .ham8.active .middle {
        -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
    }

    .ham8.active .bottom {
        stroke-dashoffset: -64px;
    }

    .hamburger-menu {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    /* Deze animatie moet worden veranderd naar keyframes */

    .bottomBar-menu {
        width: 100vw;
        height: 100vh;
        background: var(--styleColor2);
        position: absolute;
        bottom: -25px;
        left: -5vw;
        -webkit-transition: 0.4s ease-in-out;
        -o-transition: 0.4s ease-in-out;
        transition: 0.4s ease-in-out;
        -webkit-transform-origin: 50% 50%;
        -ms-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
    }

    .bottomBar-menu {
        width: 70px;
        height: 70px;
        bottom: 0;
        left: 50%;
        -webkit-transform: translate(-50%);
        -ms-transform: translate(-50%);
        transform: translate(-50%);
        border-radius: 50%;
    }

    .nav-mobile-menu {
        display: block;
    }

    .nav-mobile-menu-content {
        display: none;
        width: 100vw;
        height: 100vh;
        position: absolute;
        top: 0;
        left: 0;
        background: var(--styleColor2);
        padding: 70px 7vw 90px 7vw;
    }

        .nav-mobile-menu-content > ul {
            list-style: none;
            padding: 0;
        }

    .nav-mobile-menu-container {
        position: relative;
    }

        .nav-mobile-menu-container .drop {
            position: fixed;
            right: calc(7vw);
            top: 12px;
        }

    .nav-visible {
        display: block;
    }

    .nav-item-mobile {
        padding: 10px 0;
    }

    .nav-item-version-mobile {
        position: absolute;
        width: calc(100% - 20vw);
        bottom: 25px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .nav-selections-mobile {
        margin-bottom: 10px;
    }

    .nav-link-mobile {
        font-size: 30px;
    }

    .nav-link-version-mobile {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        font-size: 11px;
    }

    .nav-icon-mobile {
        width: 50px;
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex-item-align: center;
        align-self: center;
        margin-right: 10px;
    }

    .nav-topBar a:hover i, .nav-topBar a:hover h6, .nav-item:hover .nav-icon, .nav-item:hover .nav-icon-text {
        color: var(--textColorLight);
    }

    .nav-version-mobile {
        -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
    }

    .select2-container {
        width: 100% !important;
    }

    #Organisations > div > span > span.selection > span, .select2-container--default .select2-selection--single {
        height: 35px;
        padding: 6px 35px 6px 15px;
    }

        .select2-container--default .select2-selection--single .select2-selection__arrow {
            top: 3px;
        }

    .select2-results__option {
        padding: 10px;
        border-radius: 50px;
    }

    .notMobile {
        display: none;
    }

    .onlyMobile {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
    }

    .onlyMobileMenu {
        display: none;
    }

    .login-lang {
        width: 100%;
        position: absolute;
        top: calc(5vh + 5px);
        left: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        padding: 0 5vw;
    }

        .login-lang .drop {
            display: block
        }

    .nav-topBar-ul {
        width: 100%;
        padding: 0;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }

    .nav-logo-mobile {
        height: 40px;
        position: relative;
        top: -5px;
    }

    .content-container-login {
        width: 100%;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        top: 0;
        border-radius: 0;
        padding: 0;
        background-image: unset;
        background-color: unset;
        position: unset;
        overflow-y: auto;
        overflow-x: hidden;
        background-position: unset;
        background-repeat: unset;
        background-size: unset;
        -webkit-box-shadow: unset;
        box-shadow: unset
    }

    .login-body {
        //background-image: url(../../../images/clouds2.svg) !important;
        padding: 5vh 5vw;
        background-image: unset;
        background-color: #FFFFFF;
        min-height: 100vh;
        height: fit-content;
        overflow-y: auto;
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;

        @include size(tablet) {
            padding: 0;
            //background-image: none;
        }
    }

    .login-logo {
        z-index: 100;
        position: unset;
        margin: 0 0 50px 15px;
    }

    .login-title {
        margin: 0 0 20px 15px;
        padding: 0;
    }

    .login-content-group-container-bottom {
        position: relative;
        width: 100%;
        /*margin-top: 100px;*/
    }

    .login-content-group > a {
        font-size: var(--inputFontSize)
    }

    .module-tab-content-body-group > input {
        background: #EEE !important;
    }

    .nav-footer-login.nav-footer-mobile > p {
        display: block;
        margin-bottom: 0;
    }

    /*.module-content-tab-btns-2 .content-btn {
        width: 100%;
    }*/
}

@media only screen and (max-width: 420px) {
    body {
        /*border-top: 5px solid red;*/
    }

    .nav-icon {
        font-size: 20px;
    }

    .line {
        stroke-width: 6px;
    }

    .nav-topBar {
        height: 50px;
        padding: 10px 5vw;
    }

    .nav-logo > a > img {
        top: 10px;
        left: 5vw;
        width: 30px;
    }

    .content-container {
        top: 50px;
        height: calc(100vh - 50px);
    }

    .nav-logo-mobile {
        height: 30px;
        top: 0;
    }
}
