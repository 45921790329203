﻿a:not([href]):not([tabindex]) {
    color: var(--textColorLight);

    &:focus, &:hover {
        color: inherit;
    }
}

.content-btn {
    color: var(--textColorLight);
    border-radius: var(--roundCorner2);
    border: none;
    white-space: nowrap;
    //margin: 0 0 0 15px;
    margin: 0;
    padding: var(--padding1);
    cursor: pointer;
    font-size: var(--inputFontSize);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: fit-content;
    height: fit-content;
    display: flex;
    gap: 10px;

    &:hover {
        color: var(--textColorLight);
        text-decoration: none;
    }

    &:first-child {
        margin-left: 0;
    }

    &.btn-irc-grid:empty {
        background: none;
        box-shadow: none;
        border: none;
        border-radius: 0;
        padding: 0;
        margin: 0;

        &:hover {
            background: none;
            box-shadow: none;
            border: none;
            border-radius: 0;
            padding: 0;
            margin: 0;
        }
    }

    &.deselected {
        opacity: 0.6;
    }

    .checkMarkContainer {
        position: absolute;
        width: 100%;
        height: 100%;
        padding: inherit;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: inherit;
        border-radius: inherit;
        border: inherit;
    }
}

.content-btn-1 {
    background: var(--buttonColorGreen);
    /*-webkit-box-shadow: 0 5px 7px 0 rgba($buttonColorGreenRGB,0.3);
    box-shadow: 0 5px 7px 0 rgba($buttonColorGreenRGB,0.3);*/
    -webkit-box-shadow: 0 5px 7px 0 rgba(21, 195, 154, 0.3);
    box-shadow: 0 5px 7px 0 rgba(21, 195, 154, 0.3);

    &:hover {
        /*-webkit-box-shadow: rgba($buttonColorGreenRGB,0.16) 0 10px 20px, rgba($buttonColorGreenRGB,0.3) 0 6px 6px;
        box-shadow: rgba($buttonColorGreenRGB,0.16) 0 10px 20px, rgba($buttonColorGreenRGB,0.3) 0 6px 6px;*/
        -webkit-box-shadow: rgba(21, 195, 154,0.16) 0 10px 20px, rgba(21, 195, 154,0.3) 0 6px 6px;
        box-shadow: rgba(21, 195, 154,0.16) 0 10px 20px, rgba(21, 195, 154,0.3) 0 6px 6px;
    }

    &:active {
        /*-webkit-box-shadow: rgba($buttonColorGreenRGB,0.12) 0 1px 3px, rgba($buttonColorGreenRGB,0.24) 0 1px 2px;
        box-shadow: rgba($buttonColorGreenRGB,0.12) 0 1px 3px, rgba($buttonColorGreenRGB,0.24) 0 1px 2px;*/
        -webkit-box-shadow: rgba(21, 195, 154,0.12) 0 1px 3px, rgba(21, 195, 154,0.24) 0 1px 2px;
        box-shadow: rgba(21, 195, 154,0.12) 0 1px 3px, rgba(21, 195, 154,0.24) 0 1px 2px;
    }
}



.content-btn-2 {
    background: var(--buttonColorRed);
    -webkit-box-shadow: 0 5px 7px 0 rgba(204, 59, 51,0.3);
    box-shadow: 0 5px 7px 0 rgba(204, 59, 51,0.3); /*
    -webkit-box-shadow: 0 5px 7px 0 rgba($buttonColorRedRGB,0.3);
    box-shadow: 0 5px 7px 0 rgba($buttonColorRedRGB,0.3);*/

    &:hover {
        -webkit-box-shadow: rgba(204, 59, 51,0.16) 0 10px 20px, rgba(204, 59, 51,0.3) 0 6px 6px;
        box-shadow: rgba(204, 59, 51,0.16) 0 10px 20px, rgba(204, 59, 51,0.3) 0 6px 6px; /*
        -webkit-box-shadow: rgba($buttonColorRedRGB,0.16) 0 10px 20px, rgba($buttonColorRedRGB,0.3) 0 6px 6px;
        box-shadow: rgba($buttonColorRedRGB,0.16) 0 10px 20px, rgba($buttonColorRedRGB,0.3) 0 6px 6px;*/
    }

    &:active {
        -webkit-box-shadow: rgba(204, 59, 51,0.12) 0 1px 3px, rgba(204, 59, 51,0.24) 0 1px 2px;
        box-shadow: rgba(204, 59, 51,0.12) 0 1px 3px, rgba(204, 59, 51,0.24) 0 1px 2px; /*
        -webkit-box-shadow: rgba($buttonColorRedRGB,0.12) 0 1px 3px, rgba($buttonColorRedRGB,0.24) 0 1px 2px;
        box-shadow: rgba($buttonColorRedRGB,0.12) 0 1px 3px, rgba($buttonColorRedRGB,0.24) 0 1px 2px;*/
    }
}



.content-btn-3 {
    background: rgba(0, 0, 0, 0);
    -webkit-box-shadow: none;
    box-shadow: none;
    color: $default-font-color-dark;

    &:hover {
        -webkit-box-shadow: none;
        box-shadow: none;
        color: var(--buttonColorGreen);
    }

    &:active {
        -webkit-box-shadow: none;
        box-shadow: none;
    }
}


.content-btn-4 {
    background: var(--buttonColorBlue);
    -webkit-box-shadow: 0 5px 7px 0 rgba(34, 98, 133,0.3);
    box-shadow: 0 5px 7px 0 rgba(34, 98, 133,0.3); /*
    -webkit-box-shadow: 0 5px 7px 0 rgba(var(--buttonColorBlueRGB),0.3);
    box-shadow: 0 5px 7px 0 rgba(var(--buttonColorBlueRGB),0.3);*/

    &:hover {
        -webkit-box-shadow: rgba(34, 98, 133,0.16) 0 10px 20px, rgba(34, 98, 133,0.3) 0 6px 6px;
        box-shadow: rgba(34, 98, 133,0.16) 0 10px 20px, rgba(34, 98, 133,0.3) 0 6px 6px; /*
        -webkit-box-shadow: rgba(var(--buttonColorBlueRGB),0.16) 0 10px 20px, rgba(var(--buttonColorBlueRGB),0.3) 0 6px 6px;
        box-shadow: rgba(var(--buttonColorBlueRGB),0.16) 0 10px 20px, rgba(var(--buttonColorBlueRGB),0.3) 0 6px 6px;*/
    }

    &:active {
        -webkit-box-shadow: rgba(34, 98, 133,0.12) 0 1px 3px, rgba(34, 98, 133,0.24) 0 1px 2px;
        box-shadow: rgba(34, 98, 133,0.12) 0 1px 3px, rgba(34, 98, 133,0.24) 0 1px 2px; /*
        -webkit-box-shadow: rgba(var(--buttonColorBlueRGB),0.12) 0 1px 3px, rgba(var(--buttonColorBlueRGB),0.24) 0 1px 2px;
        box-shadow: rgba(var(--buttonColorBlueRGB),0.12) 0 1px 3px, rgba(var(--buttonColorBlueRGB),0.24) 0 1px 2px;*/
    }
}


.content-btn-5 {
    background: var(--buttonColorGrey);
    -webkit-box-shadow: 0 5px 7px 0 rgba(170, 170, 170,0.3);
    box-shadow: 0 5px 7px 0 rgba(170, 170, 170,0.3); /*
    -webkit-box-shadow: 0 5px 7px 0 rgba(var(--buttonColorGreyRGB),0.3);
    box-shadow: 0 5px 7px 0 rgba(var(--buttonColorGreyRGB),0.3);*/

    &:hover {
        -webkit-box-shadow: rgba(170, 170, 170,0.16) 0 10px 20px, rgba(170, 170, 170,0.3) 0 6px 6px;
        box-shadow: rgba(170, 170, 170,0.16) 0 10px 20px, rgba(170, 170, 170,0.3) 0 6px 6px; /*
        -webkit-box-shadow: rgba(var(--buttonColorGreyRGB),0.16) 0 10px 20px, rgba(var(--buttonColorGreyRGB),0.3) 0 6px 6px;
        box-shadow: rgba(var(--buttonColorGreyRGB),0.16) 0 10px 20px, rgba(var(--buttonColorGreyRGB),0.3) 0 6px 6px;*/
    }

    &:active {
        -webkit-box-shadow: rgba(170, 170, 170,0.12) 0 1px 3px, rgba(170, 170, 170,0.24) 0 1px 2px;
        box-shadow: rgba(170, 170, 170,0.12) 0 1px 3px, rgba(170, 170, 170,0.24) 0 1px 2px; /*
        -webkit-box-shadow: rgba(var(--buttonColorGreyRGB),0.12) 0 1px 3px, rgba(var(--buttonColorGreyRGB),0.24) 0 1px 2px;
        box-shadow: rgba(var(--buttonColorGreyRGB),0.12) 0 1px 3px, rgba(var(--buttonColorGreyRGB),0.24) 0 1px 2px;*/
    }
}