﻿.documentBuilder {
    width: 100%;

    .element, .row {
        display: grid;
        grid-template-columns: 40px 1fr 40px;
        border: $default-border;
        border-radius: $default-border-radius;

        &:not(:last-child) {
            margin-bottom: 10px;
        }

        .column {
            display: flex;
            padding: 10px;

            &:not(:last-child) {
                border-right: $default-border-line;
            }
            /*&.column.content {
                padding: 0;
            }*/

            &.order {
                justify-content: center;
                align-items: center;
                padding-left: 0;
                padding-right: 0;
                grid-row: 1 / 3;

                .handle {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: move; /* fallback if grab cursor is unsupported */
                    cursor: grab;
                    cursor: -moz-grab;
                    cursor: -webkit-grab;

                    &:active {
                        cursor: grabbing;
                        cursor: -moz-grabbing;
                        cursor: -webkit-grabbing;
                    }
                }
            }

            &.content {
                justify-content: left;

                .input {
                    padding-top: 0;
                    padding-bottom: 0;
                    width: 100%;

                    &:first-child {
                        padding-left: 0;
                    }

                    &:last-child {
                        padding-right: 0;
                    }

                    .transforming-input {
                        width: 100%;
                        border-radius: 10px;

                        &[readonly] {
                            /*background: var(--backgroundColorNotAllowed);*/
                        }
                    }
                }
            }

            &.options {
                justify-content: center;
                align-items: center;
                padding-left: 0;
                padding-right: 0;
                position: relative;

                .row-dropdown {
                    top: 50%;
                }

                .content-btn {
                    padding: 0;
                    height: 100%;
                    width: 100%;

                    i {
                        height: 100%;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }

            .remark {
                grid-row: 2;
                grid-column: -3 / -2;
            }
        }

        &.column-header {
            height: fit-content;
            padding-top: 0;
            border: 5px solid transparent;
            text-align: center;

            .column {
                align-items: flex-start;
                padding: 0;
                width: 100%;
                height: 100%;

                p {
                    width: 100%;
                    text-align: center;
                    font-weight: 700;
                }
            }
        }

        .table {
            margin: 0;
            padding: 0;
            overflow: visible;

            .element, .row {
                display: grid;
                grid-template-columns: 40px 1fr 40px;
            }

            .element {

                &:focus-within {
                    border: 5px solid $default-style-color-1;
                }

                .options {
                    grid-row: 1 / 3;
                }
            }
        }
    }

    textarea {
        font-size: var(--inputFontSize);
        font-weight: var(--fontWeight2);
        width: 100%;
        float: left !important;
        border: none;
        color: #333333;
        padding: var(--padding6);
        margin: 0;
        border-radius: $default-border-radius;
        background: var(--backgroundColor2);
    }

    input {
        font-weight: var(--fontWeight2);
        font-size: var(--inputFontSize);
        width: 100%;
        float: left !important;
        border: none;
        color: #333333;
        padding: var(--padding4);
        margin: 0;
        border-radius: var(--roundCorner2);
        background: var(--backgroundColor2);
        height: fit-content;
        line-height: 1.5;
        display: block;
    }
}

.elementOptions {
    width: 100%;

    .option {
        width: 100%;
        border: $default-border;
        border-radius: $default-border-radius;
        padding: 15px;
        cursor: pointer;

        &:not(:last-child) {
            margin-bottom: 10px;
        }

        h5 {
            margin-bottom: 0.3em;
        }
    }
}

.product-options {
    width: 100%;
    display: flex;

    .product-list {
        width: 33%;
        border-right: $default-border-line;
        padding-right: 15px;

        .module-content-tab-search {
            padding: 0;

            button {
                top: 5px;
                right: 11px;
            }
        }

        ul {
            margin: 0;
            padding: 0;
            list-style: none;
        }
    }

    .product-details {
        width: 67%;
        padding-left: 15px;
    }
}