﻿@import 'GeneralVariables';

/* ----- Functions ----- */

@mixin fontDefinition($font-family: $default-font-family-text, $font-size: 1em, $font-weight: $default-font-weight, $color: $default-font-color-dark, $line-height: $default-font-lineHeight, $margin: $default-font-margin) {
    font-family: $font-family;
    font-size: $font-size;
    font-weight: $font-weight;
    color: $color;
    line-height: $line-height;
    margin: $default-font-margin;
    padding: 0;

    &:last-child {
        margin: 0;
    }
}

@mixin size($size) {
    @if $size == mobile {
        @media only screen and (max-width: 420px) {
            @content;
        }
    }

    @else if $size == tablet-small {
        @media only screen and (max-width: 768px), only screen and (max-device-width: 896px) and (orientation: landscape) {
            @content;
        }
    }

    @else if $size == tablet-regular {
        @media only screen and (max-width: 900px) {
            @content;
        }
    }

    @else if $size == tablet {
        @media only screen and (max-width: 1150px) {
            @content;
        }
    }

    @else if $size == desktop-small {
        @media only screen and (max-width: 1224px) {
            @content;
        }
    }

    @else if $size == desktop {
        @media only screen and (max-width: 1300px) {
            @content;
        }
    }

    @else if $size == desktop-large {
        @media only screen and (max-width: 1500px) {
            @content;
        }
    }

    @else if $size == desktop-larger {
        @media only screen and (max-width: 1600px) {
            @content;
        }
    }

    @else if $size == screen {
        @media only screen and (max-width: 2300px) {
            @content;
        }
    }

    @else if $size == screen-large {
        @media only screen and (max-width: 3000px) {
            @content;
        }
    }

    @else if $size == screen-larger {
        @media only screen and (max-width: 4700px) {
            @content;
        }
    }

    @else if $size == screen-enormous {
        @media only screen and (min-width: 4700px) {
            @content;
        }
    }
}

//@mixin size($size) {
//    @if $size == mobile {
//        @media only screen and (max-width: 420px) {
//            @content;
//        }
//    }
//
//    @else if $size == tablet {
//        @media only screen and (max-width: 1150px) {
//            @content;
//        }
//    }
//
//    @else if $size == desktop {
//        @media only screen and (max-width: 1300px) {
//            @content;
//        }
//    }
//
//    @else if $size == screen {
//        @media only screen and (max-width: 2300px) {
//            @content;
//        }
//    }
//}

@mixin gridColumns($min-size: $default-grid-min-width) {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax($min-size, 1fr));

    @include size(tablet-small) {
        grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    }
}

@mixin hoverStyle($type: '', $otherElems: '') {
    &:hover #{$otherElems} {
        @if $type == link {
            font-weight: 700;
            @content;
        }

        @else if $type == icon {
            opacity: 0.7;
            @content;
        }

        @else {
            @content;
        }
    }
}




/* ----- Typography ----- */

* {
    word-break: normal;
    box-sizing: border-box;
}

body {
    @include fontDefinition($default-font-family-text, $default-font-size, $default-font-weight, $default-font-color-dark);
}

#root {
    display: flex;
    flex-direction: row-reverse;
    background: $default-background-color;
    overflow: hidden;
    /*height: 100vh;
    height: -webkit-fill-available;
    height: fill-available;*/
    
    @include size(tablet) {
        flex-direction: unset;
        flex-flow: column wrap;
    }

    @include size(mobile) {
        background: none;
    }
}

h1 {
    @include fontDefinition($default-font-family-headline, $default-font-size-h1, $default-font-weight-h1, $default-font-color-dark);
}

h2 {
    @include fontDefinition($default-font-family-headline, $default-font-size-h1, $default-font-weight-h1, $default-font-color-dark);
}

h3 {
    @include fontDefinition($default-font-family-headline, $default-font-size-h3, $default-font-weight-h3, $default-font-color-dark);
}

h4 {
    @include fontDefinition($default-font-family-text, $default-font-size-h4, $default-font-weight-h4, $default-font-color-dark);
}

h5 {
    @include fontDefinition($default-font-family-headline, $default-font-size-h5, $default-font-weight-h5, $default-font-color-dark);
}

h6 {
    @include fontDefinition($default-font-family-text, $default-font-size-h6, $default-font-weight-h6, $default-font-color-dark);
}

p {
    @include fontDefinition($default-font-family-text, $default-font-size-h6, $default-font-weight-h6, $default-font-color-dark);
}

a {
    text-decoration: none;
    color: $default-a-color;

    & > p {
        color: $default-font-color-dark;
    }

    &:hover {
        text-decoration: none;
        color: $default-a-color-hover;
    }
}

.platform {
    display: none;
}

details {
    padding: 10px 0;

    &:not(:last-child) {
        border-bottom: var(--borderBottom1);
    }

    h4 {
        font-weight: var(--fontWeight3);
        margin-bottom: 5px;
    }

    summary {
        list-style: none;
        display: flex;
        align-items: center;

        & h5 {
            margin: 0;
            width: 100%;
            text-align: center;
        }

        i {
            margin-left: auto;
            padding-right: 15px;
            font-size: var(--caretSize1);
        }
    }

    &[open] > summary {
        margin-bottom: 15px;
    }
}