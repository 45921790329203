﻿.productList-overview {
    margin: 0;
    padding: 0;
    border: 0;
}

.productList-overview-item {
    margin: 0 0 10px 0;
    padding: 0;
    border: 0;
    list-style: none;

    .productList-overview-item-addProduct {
        margin: 0;
        padding: 0;
        width: 100%;
        border: none;
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        background: none;
    }
}

.productList-overview-item-hide {
    display: none;
}

.productList-overview-item-name {
    margin: 0;
    padding: 5px 15px;
    border: 0;
    width: 100%;
    display: inline-flex;
    align-items: center;
    background: var(--backgroundColor2);
    border-radius: 10px;
    position: relative;

    h5 {
        margin: 0 10px;
        font-size: var(--legendHeadFontSize);
    }

    i {
        font-size: var(--caretSize1);
        color: $default-font-color-dark;
        margin: 0 10px 0 0;
        height: 17px;
        width: 17px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    button i {
        margin: 0;
        font-size: 12px;
    }

    a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin: 0 0 0 auto;
    }
}

.productList-overview-item-details {
    margin: 0;
    padding: 0;
    border: 0;
    display: none;

    ul {
        margin: 0;
        padding: 0;
        border: 0;
        list-style: none;

        li {
            margin: 0;
            padding: 3px 15px;
            display: inline-flex;
            column-gap: 10px;
            width: 100%;
            /*border-radius: 10px;*/
            border-bottom: 1px solid #DDD;

            &:first-child {
                margin-top: 10px;
            }

            &:last-child {
                border-bottom: none;
            }
            /*&:nth-child(2n+1) {
                background: var(--backgroundColor2);
            }*/

            p {
                margin: 0;
                width: 50%;

                &:first-child {
                    font-weight: var(--fontWeight3);
                }
            }
        }
    }
}

.productList-overview-options {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0 15px;
}

.hide-productList-overview-options {
    display: none;
}

.productList-overview-item-active {
    display: block;
}

.hide-newPricelist-module {
    display: none;
}
