﻿.content {
    background: $default-background-color;
    width: 100%;
    max-height: 100vh;
    height: 100vh;
    padding: $default-menu-padding;
    flex: 1 1 auto;
    overflow-y: auto;
    display: flex;
    flex-flow: column nowrap;
    position: relative;

    @include size(tablet) {
        height: calc(100vh - 60px) !important;
    }

    .header {
        width: 100%;
        margin-bottom: 50px;
    }

    .info {
        margin-bottom: 25px !important;

        @include size(tablet) {
            background: var(--backgroundColor2);
            padding: var(--padding2);
            border-radius: 10px;
        }
    }

    .body {
        padding-bottom: $default-body-padding-bottom;
    }
}

.tabs {
    display: flex;
    width: 100%;
    padding: 0 10px;
    margin: 0;
    list-style: none;

    .tab {
        padding: 5px 10px;
        background: var(--backgroundColor2);
        border-radius: 10px 10px 0 0;
        cursor: pointer;

        &.active {
            //padding-top: 15px;
            background: var(--styleColor3);
        }
    }
}

.actions {
    background: $default-style-color-5;
    width: 100%;
    padding: $default-menu-padding;
    border-radius: 10px;
    display: flex;
    gap: 15px;
    margin-bottom: 25px !important;

    .left, .right {
        display: flex;
        gap: 10px;

        &.right {
            margin-left: auto;
        }

        .item-container {
            display: flex;
            flex-flow: column wrap;

            &:not(:last-child) {
                margin-right: 10px;
                padding-right: 10px;
                border-right: var(--borderBottom1);
            }

            h5 {
                text-align: left;
            }
        }
    }
}

.messages {
    position: sticky;
    top: 0;
    z-index: 1;
    margin-bottom: 20px;
    
    .actions {
        background: none;
        padding: 0;
        display: flex;
        flex-direction: column;
    }
}

.MuiAccordion-root {
    border-radius: 10px !important;
    overflow: hidden;
    
    //&:not(.Mui-expanded) {
    //    position: sticky;
    //    top: 0;
    //}
    
    &.Mui-expanded {
        overflow: unset;
    }
    
    &:not(:last-child) {
        margin-bottom: 10px;
    }
    
    &.Mui-expanded {
        border-radius: 10px 10px 0 0;
    }
    
    .actions {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-top: var(--borderBottom1);
    }

    .MuiAccordionSummary-root{
        //position: sticky;
        //top: 0;
        
        &:before {
            all: unset;
            display: none !important;
        }
    }
    
    .content-masonry {
        margin-top: 20px;
    }
}